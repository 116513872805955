import "./datatable.css";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns, userRows } from "./datatablesource";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";
import axios from "axios";

const Datatable = ({ columns, data, type }) => {
  const location = useLocation();
  // const path = location.pathname.split("/")[1];
  // const paths = location.pathname.split("/")[2];
  const { user } = useContext(AuthContext);
  // console.log("teacheruser",user.teacherSection);
  // const paths = location.pathname.split("/")[2];

  // const { data, loading, error } = useFetch("/complains");
  // const { data, loading, error } = useFetch(`https://api.studentrays.com/api/${paths}`);
  // console.log("Datatable Data",data);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/users/${params.row._id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="datatable">
        {/* <div className="datatableTitle">{path}</div> */}
        <div className="datatableTitle">{type}</div>
        <DataGrid
          className="datagrid"
          rows={data}
          columns={columns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
          checkboxSelection
          getRowId={(row) => row._id}
        />
      </div>
    </>
  );
};

export default Datatable;
