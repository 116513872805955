// export const userInputs = [
//   {
//     id: "username",
//     label: "Username",
//     type: "text",
//     placeholder: "Account_user_name",
//   },
//   {
//     id: "name",
//     label: "Name",
//     type: "text",
//     placeholder: "Enter youe Nmae",
//   },
//   {
//     id: "email",
//     label: "Email",
//     type: "email",
//     placeholder: "your_email@gmail.com",
//   },
//   {
//     id: "phone",
//     label: "Phone",
//     type: "text",
//     placeholder: "+1 234 567 89",
//   },
//   {
//     id: "password",
//     label: "Password",
//     type: "password",
//   },
//   {
//     id: "state",
//     label: "State",
//     type: "text",
//     placeholder: "Your State",
//   },
//   {
//     id: "grade",
//     label: "Grade",
//     type: "text",
//     placeholder: "Enter Grade",
//   },
//   {
//     id: "course",
//     label: "Course",
//     type: "text",
//     placeholder: "Enter course",
//   },
//   {
//     id: "section",
//     label: "Section",
//     type: "text",
//     placeholder: "Enter your Section",
//   },
// ];
export const userInputs = [
  {
    id: "username",
    label: "Username",
    type: "text",
    placeholder: "Account_user_name",
  },
  {
    id: "name",
    label: "Name",
    type: "text",
    placeholder: "Enter your Name",
  },
  {
    id: "email",
    label: "Email",
    type: "email",
    placeholder: "your_email@gmail.com",
  },
  {
    id: "phone",
    label: "Phone",
    type: "text",
    placeholder: "+1 234 567 89",
  },
  {
    id: "password",
    label: "Password",
    type: "password",
  },
  {
    id: "subject",
    label: "subject",
    type: "select",
    options: [
      { value: "1", label: "subject" },
      { value: "mat", label: "Maths" },
      { value: "sci", label: "Science" },
      { value: "Both", label: "Both" },
    ],
  },
  {
    id: "section",
    label: "section",
    type: "select",
    options: [
      { value: "1", label: "Select your section" },
      { value: "a", label: "A" },
      { value: "b", label: "B" },
      { value: "c", label: "C" },
    ],
  },
  {
    id: "state",
    label: "State",
    type: "select",
    options: [
      { value: "1", label: "Select your State" },
      { value: "Andhra Pradesh", label: "Andhra Pradesh" },
      { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
      { value: "Assam", label: "Assam" },
      { value: "Bihar", label: "Bihar" },
      { value: "Chhattisgarh", label: "Chhattisgarh" },
      { value: "Delhi", label: "Delhi" },
      { value: "Goa", label: "Goa" },
      { value: "Gujarat", label: "Gujarat" },
      { value: "Haryana", label: "Haryana" },
      { value: "Himachal Pradesh", label: "Himachal Pradesh" },
      { value: "Jammu and Kashmir", label: "Jammu and Kashmir" },
      { value: "Jharkhand", label: "Jharkhand" },
      { value: "Karnataka", label: "Karnataka" },
      { value: "Kerala", label: "Kerala" },
      { value: "Ladakh", label: "Ladakh" },
      { value: "Madhya Pradesh", label: "Madhya Pradesh" },
      { value: "Maharashtra", label: "Maharashtra" },
      { value: "Manipur", label: "Manipur" },
      { value: "Meghalaya", label: "Meghalaya" },
      { value: "Mizoram", label: "Mizoram" },
      { value: "Nagaland", label: "Nagaland" },
      { value: "Odisha", label: "Odisha" },
      { value: "Punjab", label: "Punjab" },
      { value: "Rajasthan", label: "Rajasthan" },
      { value: "Sikkim", label: "Sikkim" },
      { value: "Tamil Nadu", label: "Tamil Nadu" },
      { value: "Telangana", label: "Telangana" },
      { value: "Tripura", label: "Tripura" },
      { value: "Uttar Pradesh", label: "Uttar Pradesh" },
      { value: "Uttarakhand", label: "Uttarakhand" },
      { value: "West Bengal", label: "West Bengal" },
    ],
  },
  {
    id: "grade",
    label: "Grade",
    type: "select",
    options: [
      { value: "", label: "Select your grade" },
      { value: "6", label: "Grade 6" },
      { value: "7", label: "Grade 7" },
      { value: "8", label: "Grade 8" },
      { value: "9", label: "Grade 9" },
      { value: "10", label: "Grade 10" },
      // Add more grades as needed
    ],
  },
  {
    id: "course",
    label: "Course",
    type: "select",
    options: [
      { value: "", label: "Select your Course" },
      { value: "cbse", label: "CBSE" },
      { value: "icse", label: "ICSE" },
      // Add more courses as needed
    ],
  },
];

export const dataInputs = [
  {
    id: "grade",
    label: "Grade",
    type: "number",
    placeholder: "Your_Grade",
  },
  {
    id: "subject",
    label: "Subject",
    type: "text",
    placeholder: "your_Subject",
  },
  {
    id: "chapterCount",
    label: "ChapterCount",
    type: "number",
    placeholder: "Your_ChapterCount",
  },
];
export const chapdetailInputs = [
  {
    id: "chapterName",
    label: "ChapterName",
    type: "text",
    placeholder: "Your_chapterName",
  },
];
export const subtopicInputs = [
  {
    id: "SubTopic",
    label: "SubTopicName",
    type: "text",
    placeholder: "Your_subtopicName",
  },
];
export const sessionInputs = [
  {
    id: "topic",
    label: "SessionName",
    type: "text",
    placeholder: "Your_sessionName",
    required: true,
  },
];
export const allquestionInputs = [
  {
    id: "question",
    label: "Question",
    type: "text",
    placeholder: "Your_question",
  },
  {
    id: "correctAnswer",
    label: "Correct Answer",
    type: "text",
    placeholder: "Your_correctAnswer",
  },
];

export const productInputs = [
  {
    id: 1,
    label: "Title",
    type: "text",
    placeholder: "Apple Macbook Pro",
  },
  {
    id: 2,
    label: "Description",
    type: "text",
    placeholder: "Description",
  },
  {
    id: 3,
    label: "Category",
    type: "text",
    placeholder: "Computers",
  },
  {
    id: 4,
    label: "Price",
    type: "text",
    placeholder: "100",
  },
  {
    id: 5,
    label: "Stock",
    type: "text",
    placeholder: "in stock",
  },
];

// export const hotelInputs = [
//   {
//     id: "name",
//     label: "Name",
//     type: "text",
//     placeholder: "My Hotel",
//   },
//   {
//     id: "type",
//     label: "Type",
//     type: "text",
//     placeholder: "hotel",
//   },
//   {
//     id: "city",
//     label: "City",
//     type: "text",
//     placeholder: "New York",
//   },
//   {
//     id: "address",
//     label: "Address",
//     type: "text",
//     placeholder: "elton st, 216",
//   },
//   {
//     id: "distance",
//     label: "Distance from City Center",
//     type: "text",
//     placeholder: "500",
//   },
//   {
//     id: "title",
//     label: "Title",
//     type: "text",
//     placeholder: "The best Hotel",
//   },
//   {
//     id: "desc",
//     label: "Description",
//     type: "text",
//     placeholder: "description",
//   },
//   {
//     id: "cheapestPrice",
//     label: "Price",
//     type: "text",
//     placeholder: "100",
//   },
// ];

export const roomInputs = [
  {
    id: "title",
    label: "Title",
    type: "text",
    placeholder: "2 bed room",
  },
  {
    id: "desc",
    label: "Description",
    type: "text",
    placeholder: "King size bed, 1 bathroom",
  },
  {
    id: "price",
    label: "Price",
    type: "number",
    placeholder: "100",
  },
  {
    id: "maxPeople",
    label: "Max People",
    type: "number",
    placeholder: "2",
  },
];
