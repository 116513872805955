import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import AssignteacherDatatable from "../../components/datatable/AssignteacherDatatable";
import Form from "react-bootstrap/Form";
import EditBatch from "./EditBatch";
// import "./assignteacher.css";
import axios from "axios";
const Batch = ({ columns }) => {
  const location = useLocation();
  const today = new Date().toISOString().split("T")[0];
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedWeekdays, setSelectedWeekdays] = useState(new Set());
  const code = location.pathname.split("/")[4];
  const [BatchDates, setBatchDates] = useState([]);
  const [Batchs, setBatchs] = useState([]);
  const [teacherData, setTeacherData] = useState([]);
  const [selectedTeacherId, setSelectedTeacherId] = useState("");
  const [Links, setLinks] = useState([]);
  const [getauthorizationUrl, setGetauthorizationUrl] = useState("");

  const fetchData = async (batch) => {
    try {
      const response = await axios.get(
        `https://api.studentrays.com/api/users/allteacher/${batch}`
      );
      console.log("teach", response);
      const data = response.data;
      setTeacherData(data);
      console.log("responseteach", response);
    } catch (error) {
      console.log(error);
    }
  };

  const handlemeetzoomClick = (requestData) => {
    // Define the data to be sent in the request body

    const requestDatas = {
      batch: requestData.batch,
      startDate: requestData.startDate,
      endDate: requestData.endDate,
      startTime: requestData.startTime,
      endTime: requestData.endTime,
      selectedDays: requestData.selectedDays,
      code: requestData.code,
    };
    // const requestDatas = {
    //     batch: "cbse8scib",
    //     startDate: "2024-05-03",
    //     endDate: "2024-05-29",
    //     startTime: "18:00",
    //     endTime: "19:00",
    //     selectedDays: ["monday", "thursday"],
    //     code: "g9y5mMZ23U6F0Q7Sd1_TFa5-fwTY20QdA",
    //   };
    console.log("request  Datas", requestDatas);
    // Send a POST request to the specified URL with the data in the request body
    axios
      .post(
        "https://api.studentrays.com/api/generate-zoom-meeting-dates",
        requestDatas
      )
      .then((response) => {
        setBatchDates(response.data.startDateTimeList);
        console.log("Response:", response.data.startDateTimeList);
        console.log(BatchDates, 277);
        handlebatchmeetzoomClick(requestData, response.data.startDateTimeList);
        // console.log('Response:', response.data.startDateTimeList);
        // Handle the response if needed
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors if needed
      });
  };
  const handlebatchmeetzoomClick = (requestData, batchDates) => {
    // Define the data to be sent in the request body
    // console.log(BatchDates, 277);
    const requestDatas = {
      batch: requestData.batch,
      startDate: requestData.startDate,
      endDate: requestData.endDate,
      startTime: requestData.startTime,
      endTime: requestData.endTime,
      selectedDays: requestData.selectedDays,
      code: requestData.code,
      BatchDates: batchDates,
    };
    // code: getCodeFromUrl(),
    // const requestDatas = {
    //   batch: "cbse8scib",
    //   startDate: "2024-05-03",
    //   endDate: "2024-05-29",
    //   startTime: "18:00",
    //   endTime: "19:00",
    //   selectedDays: ["monday", "thursday"],
    //   code: "D03a6VqA3ZDqWT2xtXqRlmfVCXhCooDVw",
    //   BatchDates: [
    //     "2024-05-06",
    //     "2024-05-09",
    //     "2024-05-13",
    //     "2024-05-16",
    //     "2024-05-20",
    //     "2024-05-23",
    //     "2024-05-27",
    //   ],
    // };

    console.log(batchDates, 277);
    console.log(requestDatas, 3);

    // Send a POST request to the specified URL with the data in the request body
    axios
      .post("https://api.studentrays.com/api/auth/zoom/callback", requestDatas)
      .then((response) => {
        console.log("Response:", response);
        console.log("Response data:", response.data);
        console.log("batch: requestData.batch,", requestData.batch);
        const meetings = response.data;
        setLinks(meetings);
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === "OK"
        ) {
          console.log("Response:", response);
          console.log("Response data:", response.data);
          console.log("batch: requestData.batch,", requestData.batch);
          const meetings = response.data;
          setLinks(meetings);
          window.alert("calender for batch is ready now submit and create!");
        } else if (response.status >= 400 && response.status < 500) {
          window.alert(
            `Client error: ${response.status}. Please check your request and try again.`
          );
        } else if (response.status >= 500) {
          window.alert(
            `Server error: ${response.status}. Please try again later.`
          );
        } else {
          window.alert(
            `Unexpected response from Zoom. Status: ${response.status}`
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors if needed
      });
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value);
  };

  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value);
  };

  const handleWeekdayChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      // Add the selected weekday to the set
      setSelectedWeekdays(
        (prevSelectedWeekdays) => new Set([...prevSelectedWeekdays, name])
      );
    } else {
      // Remove the deselected weekday from the set
      setSelectedWeekdays((prevSelectedWeekdays) => {
        const updatedWeekdays = new Set(prevSelectedWeekdays);
        updatedWeekdays.delete(name);
        return updatedWeekdays;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a new FormData object from the form
    const formData = new FormData(e.target);

    // Extract the values from the FormData object
    const course = formData.get("course");
    const grade = formData.get("grade");
    const subject = formData.get("subject");
    const section = formData.get("section");
    setBatchs(getbatch(course, grade, subject, section));
    // Combine formData with other data (startDate, endDate, etc.)
    const requestData = {
      course,
      grade,
      subject,
      section,
      batch: getbatch(course, grade, subject, section),
      startDate,
      endDate,
      startTime,
      endTime,
      selectedDays: Array.from(selectedWeekdays),
      // code: getCodeFromUrl(),
    };
    localStorage.setItem("request", JSON.stringify(requestData));
    const batch = getbatch(course, grade, subject, section);
    console.log("Batchs 216", batch);
    console.log("requestData", requestData);
    fetchData(batch);
    // await handleAuth();
    console.log("requestData2", requestData);
    // handlemeetzoomClick(requestData);
    // fetchData(requestData.batch);
  };
  const local = async () => {
    let storedData = localStorage.getItem("request");
    console.log("storedData", storedData);
    if (storedData) {
      storedData = JSON.parse(storedData);
      storedData.code = await getCodeFromUrl();
      console.log("storedData1", storedData);
      await handlemeetzoomClick(storedData);
      // fetchData(storedData.batch);
    }
  };
  const createMeeting = async () => {
    try {
      const response = await axios.get(`https://api.studentrays.com/api/auth/zoom`);
      console.log(response.data.authorizationUrl, 12);
      // setGetauthorizationUrl(response.data.authorizationUrl);
      // console.log("getUrl", getauthorizationUrl);
      window.location.href = response.data.authorizationUrl;
      // set response.data.authorizationUrl in localstorage
    } catch (error) {
      console.error("Error initiating Zoom OAuth:", error);
    }
  };

  const getCodeFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    console.log(searchParams, 21);
    const code = searchParams.get("code");
    console.log(code, 23);
    setGetauthorizationUrl(code);
    if (code) {
      return code;
    }
  };
  const getbatch = (course, grade, subject, section) => {
    const batch = `${course}${grade}${subject}${section}`;
    if (batch) {
      return batch;
    }
  };
  // const handleAuth = () => {
  //   try {
  //     createMeeting();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const handleteacherSelectChange = async (name, id) => {
    const item = { name: name, id: id };
    localStorage.setItem("request1", JSON.stringify(item));
    // console.log("name, id", name, id);
    // console.log("setLinks", Links);
    // console.log("startDate", startDate);
    // console.log("endDate", endDate);
    // console.log("startTime", startTime);
    // console.log("endTime", endTime);
    createMeeting();
  };
  const submitcreatebatch = async () => {
    let storedData = localStorage.getItem("request");
    let storedData1 = localStorage.getItem("request1");
    // console.log("storedData", storedData);
    // console.log("selectedWeekdays", selectedWeekdays);
    const parsedStoredData = storedData ? JSON.parse(storedData) : {};
    const parsedStoredData1 = storedData1 ? JSON.parse(storedData1) : {};
    try {
      // Destructure properties from parsedStoredData with default values or undefined
      const {
        batch = undefined,
        endDate = undefined,
        startTime = undefined,
        endTime = undefined,
        selectedDays = [],
      } = parsedStoredData;
      const { name = undefined, id = undefined } = parsedStoredData1;

      // Construct dataToSend object
      const dataToSend = {
        Links,
        startDate,
        endDate,
        startTime,
        endTime,
        selectedDays,
        id,
        name,
        batch,
      };
      console.log("dataToSend", dataToSend);
      const calenderresponse = await axios.post(
        "https://api.studentrays.com/api/calender/data",
        dataToSend
      );
      console.log(calenderresponse, 351);
      // console.log(calenderresponse.data, 352);
      // console.log(calenderresponse.status, 353);

      // Then in your code:
      if (
        calenderresponse.status === 200 ||
        calenderresponse.status === 201 ||
        calenderresponse.statusText === "OK"
      ) {
        window.alert("Calendar updated. Zoom is working successfully!");
      } else {
        window.alert(
          `Unexpected response: Status ${calenderresponse.status}, ${calenderresponse.statusText}`
        );
      }
      setSelectedTeacherId(id);
      // localStorage.setItem("request":"");
    } catch (error) {
      console.error("Error updating calendar:", error);
      alert(`An error occurred while updating the calendar: ${error.message}`);
    }
  };
  return (
    <>
      <div className="assign">
        <Sidebar />
        <div className="listContainer">
          <Navbar />
          <h1>Create Batch</h1>
          
          {/* {marker && ( */}
          <div>
            <Form onSubmit={handleSubmit}>
              <div className="calendar">
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="ICSE"
                      name="course"
                      type={type}
                      id={`inline-${type}-1`}
                      value="icse"
                      defaultChecked
                    />
                    <Form.Check
                      inline
                      label="CBSE"
                      name="course"
                      type={type}
                      id={`inline-${type}-2`}
                      value="cbse"
                    />
                  </div>
                ))}
                <hr />
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="6"
                      name="grade"
                      type={type}
                      id={`inline-${type}-1`}
                      value="6"
                    />
                    <Form.Check
                      inline
                      label="7"
                      name="grade"
                      type={type}
                      id={`inline-${type}-1`}
                      value="7"
                    />
                    <Form.Check
                      inline
                      label="8"
                      name="grade"
                      type={type}
                      id={`inline-${type}-1`}
                      value="8"
                    />
                    <Form.Check
                      inline
                      label="9"
                      name="grade"
                      type={type}
                      id={`inline-${type}-1`}
                      value="9"
                    />
                    <Form.Check
                      inline
                      label="10"
                      name="grade"
                      type={type}
                      id={`inline-${type}-1`}
                      value="10"
                      defaultChecked
                    />
                  </div>
                ))}
                <hr />
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="Science"
                      name="subject"
                      type={type}
                      id={`inline-${type}-1`}
                      value="sci"
                    />
                    <Form.Check
                      inline
                      label="Maths"
                      name="subject"
                      type={type}
                      id={`inline-${type}-2`}
                      value="mat"
                      defaultChecked
                    />
                  </div>
                ))}
                <hr />
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="A"
                      name="section"
                      type={type}
                      id={`inline-${type}-1`}
                      value="a"
                      defaultChecked
                    />
                    <Form.Check
                      inline
                      label="B"
                      name="section"
                      type={type}
                      id={`inline-${type}-2`}
                      value="b"
                    />
                    <Form.Check
                      inline
                      label="C"
                      name="section"
                      type={type}
                      id={`inline-${type}-3`}
                      value="c"
                    />
                  </div>
                ))}

                <h2>Select Date time for creating new batch</h2>
                <label htmlFor="start-date">Start Date:</label>
                <input
                  type="date"
                  id="start-date"
                  value={startDate}
                  onChange={handleStartDateChange}
                />

                <label htmlFor="end-date">End Date:</label>
                <input
                  type="date"
                  id="end-date"
                  value={endDate}
                  onChange={handleEndDateChange}
                />

                <label htmlFor="start-time">Start Time:</label>
                <input
                  type="time"
                  id="start-time"
                  value={startTime}
                  onChange={handleStartTimeChange}
                />

                <label htmlFor="end-time">End Time:</label>
                <input
                  type="time"
                  id="end-time"
                  value={endTime}
                  onChange={handleEndTimeChange}
                />

                <div>
                  <p>Select Weekdays:</p>
                  <label>
                    <input
                      type="checkbox"
                      name="sunday"
                      checked={selectedWeekdays.has("sunday")}
                      onChange={handleWeekdayChange}
                    />
                    Sunday
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="monday"
                      checked={selectedWeekdays.has("monday")}
                      onChange={handleWeekdayChange}
                    />
                    Monday
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="tuesday"
                      checked={selectedWeekdays.has("tuesday")}
                      onChange={handleWeekdayChange}
                    />
                    Tuesday
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="wednesday"
                      checked={selectedWeekdays.has("wednesday")}
                      onChange={handleWeekdayChange}
                    />
                    Wednesday
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="thursday"
                      checked={selectedWeekdays.has("thursday")}
                      onChange={handleWeekdayChange}
                    />
                    Thursday
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="friday"
                      checked={selectedWeekdays.has("friday")}
                      onChange={handleWeekdayChange}
                    />
                    Friday
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="saturday"
                      checked={selectedWeekdays.has("saturday")}
                      onChange={handleWeekdayChange}
                    />
                    Saturday
                  </label>
                </div>
              </div>
              <button type="submit">Submit</button>
            </Form>
          </div>
          {/* <button onClick={() => handleAuth()}>Auth</button> */}
          {Array.isArray(teacherData) && (
            <select
              className="assignButton"
              onChange={(e) => {
                const selectedTeacher = teacherData.find(
                  (teacher) => teacher._id === e.target.value
                );
                handleteacherSelectChange(
                  selectedTeacher.username,
                  e.target.value
                );
              }}
            >
              <option value="">select one</option>
              {teacherData.map((data, index) => (
                <option key={index} value={data._id}>
                  {data.username}
                </option>
              ))}
            </select>
          )}
          <button onClick={() => local()}>Generate Meeting</button>
          <button onClick={() => submitcreatebatch()}>create batch</button>
          <hr />
          <EditBatch />
        </div>
      </div>
    </>
  );
};

export default Batch;
