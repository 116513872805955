import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import React, { useState, useEffect } from "react";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import Table from "../../components/table/Table";
import useFetch from "../../hooks/useFetch";
import { usersColumns } from "./datatable/datatablesource";
import Datatable from "./datatable/Datatable";
const Home = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [type, setType] = useState("student");

  const {
    data: userdata,
    loading,
    error,
  } = useFetch("https://api.studentrays.com/api/users/user/count");
  const { data: studentdata } = useFetch(
    "https://api.studentrays.com/api/users/student/count"
  );
  const { data: teacherdata } = useFetch(
    "https://api.studentrays.com/api/users/teacher/count"
  );
  const { data: studentgradedata } = useFetch(
    "https://api.studentrays.com/api/users/student/count/bygrade"
  );
  const { data: teachergradedata } = useFetch(
    "https://api.studentrays.com/api/users/student/count/bygrade"
  );
  const { data: studentpaid } = useFetch(
    "https://api.studentrays.com/api/users/student/paid"
  );
  const { data: studentunpaid } = useFetch(
    "https://api.studentrays.com/api/users/student/unpaid"
  );
  const { data: allData } = useFetch("https://api.studentrays.com/api/users/");
  useEffect(() => {
    if (allData && allData.length > 0) {
      setType("All Users");
      // const filteredStudents = allData.filter(item => item.isStudent);
      setFilteredData(allData);
      // console.log("filteredStudents:", filteredStudents);
    }
  }, [allData]);
  const handleWidgetClick = (type) => {
    setType(type);
    // console.log(`${type} widget button clicked`);
    // console.log("all", allData);

    if (allData) {
      if (type === "student") {
        const filteredStudents = allData.filter(
          (item) => item.isStudent === true
        );
        setFilteredData(filteredStudents);
        // console.log("filteredStudents:", filteredStudents);
      } else if (type === "teacher") {
        const filteredTeachers = allData.filter(
          (item) => item.isTeacher === true
        );
        setFilteredData(filteredTeachers);
        // console.log("filteredTeachers:", filteredTeachers);
      } else if (type === "paid") {
        const filteredTeachers = allData.filter(
          (item) => item.isStudent === true && item.payment === true
        );
        setFilteredData(filteredTeachers);
        // console.log("filteredTeachers:", filteredTeachers);
      } else if (type === "unpaid") {
        const filteredTeachers = allData.filter(
          (item) => item.isStudent === true && item.payment === false
        );
        setFilteredData(filteredTeachers);
        // console.log("filteredTeachers:", filteredTeachers);
      } else if (type === "user") {
        setFilteredData(allData);
      }
    }
  };
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget
            type="user"
            amount={userdata.userCount}
            onClick={() => handleWidgetClick("user")}
          />
          <Widget
            type="student"
            amount={studentdata.studentCount}
            onClick={() => handleWidgetClick("student")}
          />
          <Widget
            type="teacher"
            amount={teacherdata.teacherCount}
            onClick={() => handleWidgetClick("teacher")}
          />
          <Widget
            type="paid"
            amount={studentpaid.length}
            onClick={() => handleWidgetClick("paid")}
          />
          {/* <Widget
            type="unpaid"
            amount={studentunpaid.length}
            onClick={() => handleWidgetClick("unpaid")}
          /> */}
        </div>
        <Datatable columns={usersColumns} data={filteredData} type={type} />
        {/* <div className="widgets">
          <Widget type="user" />
          <Widget type="order" />
          <Widget type="earning" />
          <Widget type="balance" />
        </div> */}
        {/* <div className="charts"> */}
        {/* <Featured /> */}
        {/* <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} /> */}
        {/* </div> */}
        {/* <div className="listContainer">
          <div className="listTitle">Latest Transactions</div>
          <Table />
        </div> */}
      </div>
    </div>
  );
};

export default Home;
