export const userColumns = [
  // { field: "_id", headerName: "ID", width: 70 },
  {
    field: "user",
    headerName: "User",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.img || "https://i.ibb.co/MBtjqXQ/no-avatar.gif"} alt="avatar" />
          {params.row.username}
        </div>
      );
    },
  },
  // {
  //   field: "name",
  //   headerName: "Name",
  //   width: 230,
  // },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "phone",
    headerName: "Phone",
    width: 100,
  },
  {
    field: "state",
    headerName: "State",
    width: 100,
  },
  {
    field: "grade",
    headerName: "grade",
    width: 50,
  },
  {
    field: "section",
    headerName: "Section",
    width: 50,
  },
  // {
  //   field: "isTeacher",
  //   headerName: "teacher",
  //   width: 100,
  // },
  // {
  //   field: "phone",
  //   headerName: "Phone",
  //   width: 100,
  // },
];

export const dataColumns = [
  // { field: "_id", headerName: "ID", width: 250 },
  {
    field: "grade",
    headerName: "Grade",
    width: 50,
  },
  {
    field: "subject",
    headerName: "Subject",
    width: 200,
  },
  {
    field: "chapterCount",
    headerName: "ChapterCount",
    width: 50,
  },
  {
    field: "chapterdetails",
    headerName: "Chapterdetails",
    width: 100,
  },
];
export const studentColumns = [
  // { field: "_id", headerName: "ID", width: 70 },
  {
    field: "user",
    headerName: "User",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.img || "https://i.ibb.co/MBtjqXQ/no-avatar.gif"} alt="avatar" />
          {params.row.username}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "phone",
    headerName: "Phone",
    width: 100,
  },
  {
    field: "state",
    headerName: "State",
    width: 100,
  },
  {
    field: "grade",
    headerName: "grade",
    width: 50,
  },
  // {
  //   field: "isTeacher",
  //   headerName: "teacher",
  //   width: 100,
  // },
  // {
  //   field: "phone",
  //   headerName: "Phone",
  //   width: 100,
  // },
];
export const teacherColumns = [
  // { field: "_id", headerName: "ID", width: 70 },
  {
    field: "user",
    headerName: "User",
    width: 190,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.img || "https://i.ibb.co/MBtjqXQ/no-avatar.gif"} alt="avatar" />
          {params.row.username}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "phone",
    headerName: "Phone",
    width: 100,
  },
  {
    field: "state",
    headerName: "State",
    width: 100,
  },
  {
    field: "grade",
    headerName: "grade",
    width: 100,
  },
  {
    field: "teacherSection",
    headerName: "Section",
    width: 100,
  }
  // {
  //   field: "phone",
  //   headerName: "Phone",
  //   width: 100,
  // },
];
export const counsellorColumns = [
  // { field: "_id", headerName: "ID", width: 70 },
  {
    field: "user",
    headerName: "User",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.img || "https://i.ibb.co/MBtjqXQ/no-avatar.gif"} alt="avatar" />
          {params.row.username}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "phone",
    headerName: "Phone",
    width: 100,
  },
  {
    field: "state",
    headerName: "State",
    width: 100,
  },
  // {
  //   field: "grade",
  //   headerName: "grade",
  //   width: 100,
  // },
  // {
  //   field: "city",
  //   headerName: "City",
  //   width: 100,
  // },
  {
    field: "phone",
    headerName: "Phone",
    width: 100,
  },
];
export const assignTeacherColumns = [
  // { field: "_id", headerName: "ID", width: 70 },
  {
    field: "user",
    headerName: "User",
    width: 170,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.img || "https://i.ibb.co/MBtjqXQ/no-avatar.gif"} alt="avatar" />
          {params.row.username}
        </div>
      );
    },
  },
  // {
  //   field: "email",
  //   headerName: "Email",
  //   width: 230,
  // },

  {
    field: "phone",
    headerName: "Phone",
    width: 150,
  },
  // {
  //   field: "state",
  //   headerName: "State",
  //   width: 150,
  // },
  {
    field: "grade",
    headerName: "Grade",
    width: 100,
  },
  {
    field: "section",
    headerName: "Section",
    width: 100,
  },
];








export const roomColumns = [
  // { field: "_id", headerName: "ID", width: 70 },
  {
    field: "title",
    headerName: "Title",
    width: 230,
  },
  {
    field: "desc",
    headerName: "Description",
    width: 200,
  },
  {
    field: "price",
    headerName: "Price",
    width: 100,
  },
  {
    field: "maxPeople",
    headerName: "Max People",
    width: 100,
  },
];
