import React, { useState, useEffect } from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
const EditBatch = () => {
  const [teachers, setTeachers] = useState([]);
  const [batches, setBatches] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [selectedBatch, setSelectedBatch] = useState("");

  const location = useLocation();
  const today = new Date().toISOString().split("T")[0];
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedWeekdays, setSelectedWeekdays] = useState(new Set());
  const code = location.pathname.split("/")[4];
  const [BatchDates, setBatchDates] = useState([]);
  //   const [Batchs, setBatchs] = useState([]);
  //   const [teacherData, setTeacherData] = useState([]);
  const [selectedTeacherId, setSelectedTeacherId] = useState("");
  const [Links, setLinks] = useState([]);
  const [getauthorizationUrl, setGetauthorizationUrl] = useState("");
  useEffect(() => {
    // Fetch teacher list on mount
    axios
      .get("https://api.studentrays.com/api/users/all/teachers")
      .then((response) => setTeachers(response.data))
      .catch((error) => console.error("Error fetching teachers:", error));
  }, []);
  //   console.log("response",response)
  const handleTeacherChange = (e) => {
    const teacherId = e.target.value; // Get the teacher ID from the selected option
    console.log("Selected Teacher ID:", teacherId);

    const selectedTeacherObj = teachers.find(
      (teacher) => teacher._id === teacherId
    );
    console.log("Selected Teacher:", selectedTeacherObj);

    setSelectedTeacher(selectedTeacherObj); // Store only the teacher ID
    setSelectedTeacherId(teacherId); // Store only the teacher ID
    setBatches(selectedTeacherObj?.batch || []); // Set batches for the selected teacher
  };

  const handleBatchChange = (e) => {
    const batchId = e.target.value;
    console.log("batchId", batchId);

    setSelectedBatch(batchId);
    const item = {
      name: selectedTeacher.username,
      id: selectedTeacher._id,
      batchId: batchId,
    };
    localStorage.setItem("request1_edit", JSON.stringify(item));
    // Fetch calendar data for the selected batch
    axios
    .get(`https://api.studentrays.com/api/calender/teacher/${selectedTeacher._id}/${batchId}`)
    .then((response) => {
      console.log("Fetched Calendar Data:", response.data); // Log the response data
      setCalendarData(response.data);
    })
    .catch((error) => {
      console.error("Error fetching calendar data:", error);
    });
  };

 
  const getCodeFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    console.log(searchParams, 21);
    const code = searchParams.get("code");
    console.log(code, 23);
    setGetauthorizationUrl(code);
    if (code) {
      return code;
    }
  };
  const createMeeting = async () => {
    try {
      const response = await axios.get(`https://api.studentrays.com/api/auth/zoom`);
      console.log(response.data.authorizationUrl, 12);
      window.location.href = response.data.authorizationUrl;
    } catch (error) {
      console.error("Error initiating Zoom OAuth:", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a new FormData object from the form
    // const formData = new FormData(e.target);

    // Extract the values from the FormData object
    // const course = formData.get("course");
    // const grade = formData.get("grade");
    // const subject = formData.get("subject");
    // const section = formData.get("section");
    // setBatchs(getbatch(course, grade, subject, section));
    // Combine formData with other data (startDate, endDate, etc.)
    const requestData = {
      //   course,
      //   grade,
      //   subject,
      //   section,
      batch: selectedBatch,
      //   batch: getbatch(course, grade, subject, section),
      startDate,
      endDate,
      startTime,
      endTime,
      selectedDays: Array.from(selectedWeekdays),
      // code: getCodeFromUrl(),
    };
    localStorage.setItem("request_edit", JSON.stringify(requestData));
    // const batch = getbatch(course, grade, subject, section);
    // console.log("Batchs 216", batch);
    console.log("requestData", requestData);
    createMeeting();
    // fetchData(batch);
    // await handleAuth();
    // handlemeetzoomClick(requestData);
    // fetchData(requestData.batch);
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value);
  };

  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value);
  };

  const handleWeekdayChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      // Add the selected weekday to the set
      setSelectedWeekdays(
        (prevSelectedWeekdays) => new Set([...prevSelectedWeekdays, name])
      );
    } else {
      // Remove the deselected weekday from the set
      setSelectedWeekdays((prevSelectedWeekdays) => {
        const updatedWeekdays = new Set(prevSelectedWeekdays);
        updatedWeekdays.delete(name);
        return updatedWeekdays;
      });
    }
  };
  const local = async () => {
    let storedData = localStorage.getItem("request_edit");
    console.log("storedData", storedData);
    if (storedData) {
      storedData = JSON.parse(storedData);
      storedData.code = await getCodeFromUrl();
      console.log("storedData1", storedData);
      await handlemeetzoomClick(storedData);
      // fetchData(storedData.batch);
    }
  };
  const handlemeetzoomClick = (requestData) => {
    // Define the data to be sent in the request body

    const requestDatas = {
      batch: requestData.batch,
      startDate: requestData.startDate,
      endDate: requestData.endDate,
      startTime: requestData.startTime,
      endTime: requestData.endTime,
      selectedDays: requestData.selectedDays,
      code: requestData.code,
    };
    // const requestDatas = {
    //     batch: "cbse8scib",
    //     startDate: "2024-05-03",
    //     endDate: "2024-05-29",
    //     startTime: "18:00",
    //     endTime: "19:00",
    //     selectedDays: ["monday", "thursday"],
    //     code: "g9y5mMZ23U6F0Q7Sd1_TFa5-fwTY20QdA",
    //   };
    console.log("request  Datas", requestDatas);
    // Send a POST request to the specified URL with the data in the request body
    axios
      .post(
        "https://api.studentrays.com/api/generate-zoom-meeting-dates",
        requestDatas
      )
      .then((response) => {
        setBatchDates(response.data.startDateTimeList);
        console.log("Response:", response.data.startDateTimeList);
        console.log(BatchDates, 277);
        handlebatchmeetzoomClick(requestData, response.data.startDateTimeList);
        // console.log('Response:', response.data.startDateTimeList);
        // Handle the response if needed
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors if needed
      });
  };
  const handlebatchmeetzoomClick = (requestData, batchDates) => {
    // Define the data to be sent in the request body
    // console.log(BatchDates, 277);
    const requestDatas = {
      batch: requestData.batch,
      startDate: requestData.startDate,
      endDate: requestData.endDate,
      startTime: requestData.startTime,
      endTime: requestData.endTime,
      selectedDays: requestData.selectedDays,
      code: requestData.code,
      BatchDates: batchDates,
    };
    // code: getCodeFromUrl(),
    // const requestDatas = {
    //   batch: "cbse8scib",
    //   startDate: "2024-05-03",
    //   endDate: "2024-05-29",
    //   startTime: "18:00",
    //   endTime: "19:00",
    //   selectedDays: ["monday", "thursday"],
    //   code: "D03a6VqA3ZDqWT2xtXqRlmfVCXhCooDVw",
    //   BatchDates: [
    //     "2024-05-06",
    //     "2024-05-09",
    //     "2024-05-13",
    //     "2024-05-16",
    //     "2024-05-20",
    //     "2024-05-23",
    //     "2024-05-27",
    //   ],
    // };

    console.log(batchDates, 277);
    console.log(requestDatas, 3);

    // Send a POST request to the specified URL with the data in the request body
    axios
      .post("https://api.studentrays.com/api/auth/zoom/callback", requestDatas)
      .then((response) => {
        console.log("Response 263:", response);
        console.log("Response data:", response.data);
        console.log("batch: requestData.batch,", requestData.batch);
        const meetings = response.data;
        setLinks(meetings);
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === "OK"
        ) {
          console.log("Response:", response);
          console.log("Response data:", response.data);
          console.log("batch: requestData.batch,", requestData.batch);
          const meetings = response.data;
          setLinks(meetings);
          window.alert("calender for batch is ready now submit and create!");
        } else if (response.status >= 400 && response.status < 500) {
          window.alert(
            `Client error: ${response.status}. Please check your request and try again.`
          );
        } else if (response.status >= 500) {
          window.alert(
            `Server error: ${response.status}. Please try again later.`
          );
        } else {
          window.alert(
            `Unexpected response from Zoom. Status: ${response.status}`
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors if needed
      });
  };
  const submitcreatebatch = async () => {
    let storedData = localStorage.getItem("request_edit");
    let storedData1 = localStorage.getItem("request1_edit");
    // console.log("storedData", storedData);
    // console.log("selectedWeekdays", selectedWeekdays);
    const parsedStoredData = storedData ? JSON.parse(storedData) : {};
    const parsedStoredData1 = storedData1 ? JSON.parse(storedData1) : {};
    try {
      // Destructure properties from parsedStoredData with default values or undefined
      const {
        batch = undefined,
        startDate = undefined,
        endDate = undefined,
        startTime = undefined,
        endTime = undefined,
        selectedDays = [],
      } = parsedStoredData;
      const {
        batchId = undefined,
        name = undefined,
        id = undefined,
      } = parsedStoredData1;

      // Construct dataToSend object
      const dataToSend = {
        Links,
        startDate,
        endDate,
        startTime,
        endTime,
        selectedDays,
        id,
        name,
        batch,
      };
      console.log("dataToSend", dataToSend);
      const calenderresponse = await axios.post(
        "https://api.studentrays.com/api/calender/data",
        dataToSend
      );
      console.log(calenderresponse, 351);
      // console.log(calenderresponse.data, 352);
      // console.log(calenderresponse.status, 353);

      // Then in your code:
      if (
        calenderresponse.status === 200 ||
        calenderresponse.status === 201 ||
        calenderresponse.statusText === "OK"
      ) {
        window.alert("Calendar updated. Zoom is working successfully!");
      } else {
        window.alert(
          `Unexpected response: Status ${calenderresponse.status}, ${calenderresponse.statusText}`
        );
      }
      setSelectedTeacherId(id);
      // localStorage.setItem("request":"");
    } catch (error) {
      console.error("Error updating calendar:", error);
      alert(`An error occurred while updating the calendar: ${error.message}`);
    }
  };

const handleDelete = (eventId) => {
    // Ask for user confirmation
    const confirmDelete = window.confirm("Are you sure you want to delete this date/time from Calender?");
  
    if (confirmDelete) {
      // Perform the delete operation
      axios
        .delete(`https://api.studentrays.com/api/calender/${eventId}`)
        .then((response) => {
          console.log("Event deleted:", response.data);
          // You can update the state or perform additional actions here
          // For example, remove the deleted event from the list
          setCalendarData((prevData) => prevData.filter((event) => event._id !== eventId));
        })
        .catch((error) => {
          console.error("Error deleting event:", error);
        });
    }
  };
  return (
    <div>
      <h1>Add More Meeting for Teacher </h1>
      <label>
        Select Teacher:
        <select onChange={handleTeacherChange} value={selectedTeacher}>
          <option value="">--Select Teacher--</option>
          {teachers.map((teacher) => (
            <option key={teacher._id} value={teacher._id}>
              {teacher.username}
            </option>
          ))}
        </select>
      </label>

      {batches && (
        <label>
          Select Batch:
          <select onChange={handleBatchChange} value={selectedBatch}>
            <option value="">--Select Batch--</option>
            {batches.map((batch, index) => (
              <option key={index} value={batch}>
                {batch}
              </option>
            ))}
          </select>
        </label>
      )}

      {calendarData && (
        <div>
          <h3>Calendar Data:</h3>
          {/* <ul> */}
            {calendarData.map((event, index) => (
              <div key={event._id} style={{"display":"flex","gap":"1rem"}}>
                {/* {event.Date} | {event.startTime} - {event.endTime} | <button>Delete {event._id}</button> */}
                <p>{event.Date} | {event.startTime} - {event.endTime}</p>
                <button onClick={() => handleDelete(event._id)}>Delete</button>
              </div>
            ))}
          {/* </ul> */}
        </div>
      )}
      <Form onSubmit={handleSubmit}>
        <div className="calendar">
          <h2>Select Date time for creating new batch</h2>
          <label htmlFor="start-date">Start Date:</label>
          <input
            type="date"
            id="start-date"
            value={startDate}
            onChange={handleStartDateChange}
          />

          <label htmlFor="end-date">End Date:</label>
          <input
            type="date"
            id="end-date"
            value={endDate}
            onChange={handleEndDateChange}
          />

          <label htmlFor="start-time">Start Time:</label>
          <input
            type="time"
            id="start-time"
            value={startTime}
            onChange={handleStartTimeChange}
          />

          <label htmlFor="end-time">End Time:</label>
          <input
            type="time"
            id="end-time"
            value={endTime}
            onChange={handleEndTimeChange}
          />

          <div>
            <p>Select Weekdays:</p>
            <label>
              <input
                type="checkbox"
                name="sunday"
                checked={selectedWeekdays.has("sunday")}
                onChange={handleWeekdayChange}
              />
              Sunday
            </label>
            <label>
              <input
                type="checkbox"
                name="monday"
                checked={selectedWeekdays.has("monday")}
                onChange={handleWeekdayChange}
              />
              Monday
            </label>
            <label>
              <input
                type="checkbox"
                name="tuesday"
                checked={selectedWeekdays.has("tuesday")}
                onChange={handleWeekdayChange}
              />
              Tuesday
            </label>
            <label>
              <input
                type="checkbox"
                name="wednesday"
                checked={selectedWeekdays.has("wednesday")}
                onChange={handleWeekdayChange}
              />
              Wednesday
            </label>
            <label>
              <input
                type="checkbox"
                name="thursday"
                checked={selectedWeekdays.has("thursday")}
                onChange={handleWeekdayChange}
              />
              Thursday
            </label>
            <label>
              <input
                type="checkbox"
                name="friday"
                checked={selectedWeekdays.has("friday")}
                onChange={handleWeekdayChange}
              />
              Friday
            </label>
            <label>
              <input
                type="checkbox"
                name="saturday"
                checked={selectedWeekdays.has("saturday")}
                onChange={handleWeekdayChange}
              />
              Saturday
            </label>
          </div>
        </div>
        <button type="submit">Submit</button>
      </Form>
      <button onClick={() => local()}>Generate Meeting</button>
      <button onClick={() => submitcreatebatch()}>create batch</button>
    </div>
  );
};

export default EditBatch;
